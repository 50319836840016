
















































































































import { Component, Vue } from 'vue-property-decorator'
import { appFunc_callExit, appFunc_senToken } from '@/utils/bridge'
import { CommonModule } from '@/store/modules/common'
import { getAppWithdrawConfigList } from '@/api'
import PopUp from './components/PopUp.vue'
@Component({
  name: 'exchange',
  components: {
    PopUp
  }
})
export default class Exchange extends Vue {
  list: any = []
  // 当前用户金豆数量
  goldBean = 0
  // 今日收益
  todayIncome = 0
  // 昨日收益
  yesterdayIncome = 0
  // 实名状态(0-未实名,1-已实名)
  verification = 1
  // 是否显示实名弹窗
  isshow = false
  // 用户id
  userId = ''

  async created() {
    await this.getToken()

    //  获取金豆提现配置列表
    this.getAppWithdrawConfigList()

    // 获取当前语言
    // const data = (this.$route.query.language as string) || 'zh'
    // this.getLanguage(data)
    // CommonModule.setLanguage(data)

    // 获取用户id
    this.userId = this.$route.query.userId as any
  }

  mounted() {
    // 进入-提现
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['enter_withdrawal', 'CLK']
    })
    // 挂载完成后，判断浏览器是否支持popstate--物理返回键
    if (window.history && (window.history.pushState as any)) {
      history.pushState(null, '', document.URL)
      window.addEventListener('popstate', this.handelBack, false)
    }
  }

  //页面销毁时，取消监听。否则其他vue路由页面也会被监听--物理返回键
  destroyed() {
    window.removeEventListener('popstate', this.handelBack, false)
  }
  goBindPage() {
    this.$router.push({
      path: '/bind'
    })
  }

  // 获取金豆提现配置列表
  async getAppWithdrawConfigList() {
    const { data, code } = await getAppWithdrawConfigList()
    if (code == 1001) {
      const {
        list,
        goldBean,
        todayIncome,
        yesterdayIncome,
        verification
      } = data
      this.list = list
      this.goldBean = goldBean
      this.todayIncome = todayIncome
      this.yesterdayIncome = yesterdayIncome
      this.verification = verification
      console.log(data)
    }
  }

  handelOverlay() {
    this.isshow = false
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, '/////////////')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
    CommonModule.setUserPhone(res.mobile)
    CommonModule.setUserName(res.name)
  }

  // 返回
  handelBack() {
    appFunc_callExit()
  }

  // 进入金豆清单
  handelList() {
    this.$router.push({
      path: '/inventory',
      query: {
        language: this.language
      }
    })
  }

  // 进入确认兑换
  handelExchange(item: any) {
    // if (item.goldenBean > this.goldBean) {
    //   this.$toast(this.$t('金豆不足，暂不能兑换'))
    //   return
    // }
    this.$router.push({
      path: '/exchange-confirm',
      query: {
        language: this.language,
        list: item,
        userId: this.$route.query.userId
      }
    })
    // 选择提现金额
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['select_withdrawal_money', 'CLK']
    })
    // if (this.verification) {

    // } else {
    //   this.isshow = true
    //   // 金豆-实名认证弹窗
    //   const { aplus_queue }: any = window
    //   aplus_queue.push({
    //     action: 'aplus.record',
    //     arguments: ['real_name_authentication_popup', 'CLK']
    //   })
    // }
  }

  // 进入金豆问题
  handelProblem() {
    console.log('进入金豆问题')
    this.$router.push({
      path: '/question',
      query: {
        language: this.language
      }
    })
  }
}

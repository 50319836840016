






























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'PopUp'
})
export default class PopUp extends Vue {
  // 是否显示弹窗
  @Prop({
    type: Boolean,
    default: false
  })
  isshow!: boolean
  // 当前语言
  @Prop({
    type: String,
    default: 'ug'
  })
  language!: string
  // 标题
  @Prop({
    type: String,
    default: '实名认证'
  })
  title!: string

  // 用户id
  @Prop({
    type: String,
    default: ''
  })
  userId!: string

  // 文案
  @Prop({
    type: String,
    default: '为了打款的及时和准确性，请先实名认证'
  })
  content!: string

  // 按钮
  @Prop({
    type: String,
    default: '去实名'
  })
  btn!: string

  handelCertif() {
    this.$router.push({
      path: '/certification',
      query: { language: this.language, type: 12 as any, userId: this.userId }
    })
    // 金豆-去实名
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['remove_real_name', 'CLK']
    })
  }

  handelOverlay() {
    this.$emit('handelOverlay')
  }
}
